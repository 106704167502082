<template>
  <div class="calculator">
    <zg-slider-with-input
      :input-width="inputWidth[MARKET]"
      :label="content.amount"
      :max="LOAN_AMOUNT.MAX"
      :min="LOAN_AMOUNT.MIN"
      :read-only="readOnly"
      :step="LOAN_AMOUNT.STEP"
      :value="loanAmount"
      name="loan-amount"
      @change="setLoanAmount($event); sliderChanged('loanAmountSlider');"
      @input="setLoanAmount($event)"
    >
      <form-input
        field="loanAmountSimple"
        group="global"
        @blur="setLoanAmount()"
      />
    </zg-slider-with-input>

    <zg-slider-with-input
      :input-width="inputWidth[MARKET]"
      :label="content.repayment"
      :max="DURATION.MAX"
      :min="DURATION.MIN"
      :read-only="readOnly"
      :step="DURATION.STEP"
      :value="repaymentYears"
      name="repayment-years-amount"
      @change="setRepaymentYears($event); sliderChanged('repaymentYearsSlider');"
      @input="setRepaymentYears($event)"
    >
      <form-input
        field="repaymentYearsSimple"
        group="global"
        @input="setRepaymentYears()"
      />
    </zg-slider-with-input>

    <span
      v-show="showMonthlyCost"
      class="monthly-cost"
    >
      {{ content.monthlyCost }} {{ monthlyCost }}*
    </span>
  </div>
</template>

<script>
  import { calculate, isServer } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'
  import FormInput from '../../../../elements/_shared/misc/FormInput'

  export default {
    name: 'Calculator',
    components: {
      FormInput,
      ZgSliderWithInput: () => import(/* webpackChunkName: 'zc/zg-slider-with-input' */
        '@zc/components/ZgSliderWithInput/ZgSliderWithInput')
    },
    props: {
      visibleMonthlyCost: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data: () => ({
      hasSentInteractionEvent: false,
      inputWidth: {
        se: '130px',
        no: '120px',
        fi: '130px'
      }
    }),
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('router', ['getRoute']),
      ...mapGetters('translations', ['getLocale']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/formSteps', ['currentStep']),
      ...mapGetters('cl/debts', ['getDebtTotalRefinanceAmount']),
      ...mapGetters('cl/session', ['getSession']),
      ...mapGetters('cl/application', ['getApplicationEvents']),
      content () {
        return this.getContent?.elements?.calculator ?? {}
      },
      common () {
        return this.getContent?.common
      },
      loanAmount () {
        return this.getForm?.global?.loanAmount?.value || this.LOAN_AMOUNT.MIN
      },
      repaymentYears () {
        return this.getForm?.global?.repaymentYears?.value || this.DURATION.MIN
      },
      calculatorValues () {
        return calculate({
          market: this.MARKET,
          amount: this.loanAmount,
          duration: this.repaymentYears * 12
        })
      },
      monthlyCost () {
        const { monthlyCost } = this.calculatorValues || {}
        return this.$options.filters.currency(monthlyCost, this.MARKET, this.getLocale)
      },
      refinanceAmount () {
        const refAmount = this.getDebtTotalRefinanceAmount || 0

        if (refAmount > this.LOAN_AMOUNT.MAX) {
          return this.LOAN_AMOUNT.MAX
        }

        return refAmount
      },
      readOnly () {
        return this.getApplicationEvents?.finalizeApplication
      },
      showMonthlyCost () {
        return this.visibleMonthlyCost
      }
    },
    watch: {
      refinanceAmount (newValue, oldValue) {
        if (newValue > this.loanAmount) {
          const value = Math.round(newValue / 1000) * 1000
          this.setLoanAmount(value)
        }
      }
    },
    async serverPrefetch () {
      await this.setField({
        group: 'global',
        field: 'loanAmountSimple',
        value: this.loanAmount
      })

      this.updateDisclaimer()
    },
    async mounted () {
      this.$emit('loaded')
    },
    methods: {
      ...mapActions('form', ['setField', 'blurField']),
      ...mapActions('cl/misc', ['setDisclaimer', 'setLastInteraction', 'updateDisclaimer']),
      ...mapActions('cl/analytics', ['fieldTracking']),
      async setLoanAmount (input) {
        let value = input
        if (!value) value = this.getForm?.global?.loanAmountSimple?.value

        if (value === this.loanAmount) return
        if (value > this.LOAN_AMOUNT.MAX) value = this.LOAN_AMOUNT.MAX
        if (value < this.LOAN_AMOUNT.MIN || !value) value = this.LOAN_AMOUNT.MIN

        await this.setField({ group: 'global', field: 'loanAmount', value })
        await this.setField({ group: 'global', field: 'newLoanAmount', value })
        await this.setField({ group: 'global', field: 'loanAmountSimple', value })
        await this.blurField({ group: 'global', field: 'loanAmountSimple' })

        this.interacted()

        const trackingId = (input?.target?.closest('input') && 'loan-amount-input') || 'range-slider-loan-amount'
        await this.setLastInteraction(trackingId)
      },
      async setRepaymentYears (input) {
        let value = input
        if (!value) value = this.getForm?.global?.repaymentYearsSimple?.value

        if (value === this.repaymentYears) return
        if (value > this.DURATION.MAX) value = this.DURATION.MAX
        if (value < this.DURATION.MIN || !value) value = this.DURATION.MIN

        await this.setField({ group: 'global', field: 'repaymentYears', value })
        await this.setField({ group: 'global', field: 'newRepaymentYears', value })
        await this.setField({ group: 'global', field: 'repaymentYearsSimple', value })
        await this.blurField({ group: 'global', field: 'repaymentYearsSimple' })

        this.interacted()

        const trackingId = (input?.target?.closest('select') && 'repayment-years-select') || 'range-slider-repayment-years'
        await this.setLastInteraction(trackingId)
      },
      async sliderChanged (field) {
        if (document?.activeElement) document.activeElement.blur()
        await this.fieldTracking({ group: 'global', field, event: 'focus' })
      },
      calculatorChangedEvent () {
        if (isServer() || !this.EMIT_CALCULATOR_EVENT) return

        window.dispatchEvent(new CustomEvent('cl:calculator:update', {
          detail: this.calculatorValues
        }))
      },
      interacted () {
        this.updateDisclaimer()
        this.calculatorChangedEvent()

        if (this.hasSentInteractionEvent) return

        this.$emit('interacted', this.calculatorValues)
        this.hasSentInteractionEvent = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .calculator {
    margin: 0 0 rhythm();
  }

  .calculator > span {
    @include type-title-xs;

    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0;

    @include mq(medium) {
      justify-content: flex-start;
    }
  }
</style>
